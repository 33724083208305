//@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap&subset=cyrillic");
//@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,900&display=swap&subset=cyrillic");

$border-color: #d7dde2;

// $primary: #ecc94e;
$primary: #094067;
$danger: #fb6565;
$sidebar-color: #fff;
$sidebar-alink: #2ecc71;
$sidebar-amenu: #2c3e50;
$sidebar-width: 240px;
$outer-border: 0;

@import "reset";
@import "utils";
@import "main";
@import "switch"