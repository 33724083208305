@media only screen and (max-width: 728px) {
  .wrapper {

    .sidebar{
      &.is-open{
        margin: 0 !important;
      }
    }

    .content {
      margin: 0 !important;
    }
  }
}

.wrapper {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: stretch;

  .sidebar {
    min-width: $sidebar-width;
    max-width: $sidebar-width;
    margin-left: -$sidebar-width;
    transition: all 0.5s;

    &.is-open {
      margin-left: 1rem;
      transition: 0.5s;

      @media only screen and (max-width: 575px) {
        min-width: 100%;
        max-width: 100%;
        transition: all 0.5s, height 0s;

        .fixed {
          min-width: 100%;
          max-width: 100%;
        }

        .sidebar-header .times {
          display: unset;
        }
      }
    }

    .sidebar-header {
      margin-top: 20px;
      text-align: center;

      img {
        width: 200px;
        max-width: 220px;
      }

      .times {
        position: relative;
        float: right;
        margin: 0.5em;
        font-size: 2rem;
        cursor: pointer;
        display: none;
      }
    }

    .fixed {
      // z-index: 99999;
      min-width: $sidebar-width;
      max-width: $sidebar-width;
      position: fixed;
      top: 1rem;
      bottom: 1rem;
      overflow-y: auto;
      border-radius: 4px;
      background: $sidebar-color;
      -webkit-overflow-scrolling: auto;
      transition: 0.5s;
    }

    .nav-item {
      border-radius: 4px;

      &.menu-open {
        // background: $sidebar-amenu;

        .dropdown-toggle::after {
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }

      .nav-link {
        padding: 0.5rem 1rem;
        border-radius: 4px;
        margin-top: 4px;
        color: #000;

        &:hover {
          padding-left: 1.3rem;
          background-color: $primary;
          color: #fff;
        }

        &.active {
          background-color: $primary;
          color: #fff;

          &::before {
            border-color: $primary;
          }
        }

        &.dropdown-toggle {
          cursor: pointer;
          position: relative;

          &::after {
            border: 0;
            font-size: 12px;
            content: "\276F";
            position: absolute;
            right: 11px;
            top: 11px;

            transition: ease-in 0.2s;
          }
        }
      }
    }

    .items-menu {
      color: #fff;

      .nav-link {
        &::before {
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 50%;
          border: 0.1rem solid #000;
          display: inline-block;
          margin-right: 10px;
          margin-left: 3px;
        }
      }
    }
  }

  .content {
    width: 100%;
    margin: 1rem;
    min-height: 100vh;



    .navbar {
      background: $primary;
      border-radius: 4px;
      border-bottom: 1px solid $border-color;

      .nav-link {
        margin-left: 1.5rem;
      }

      .user-details {
        display: flex;
        align-items: center;
        color: #fff;

        p {
          &:last-child {
            opacity: .5;
          }
        }
      }
    }

    .main {
      background: #fff;
      border-radius: 4px;
    }

    &.is-open {
      @media only screen and (max-width: 575px) {
        overflow-y: hidden;
        -webkit-overflow-scrolling: none;
        margin-left: 100%;
      }
    }

    .page-header {
      h1 {
        font-size: 1.8rem;
        margin: 0;
      }
    }

    @media screen and (min-device-width: 768px) {
      .page-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

.login {
  padding: 7em 0;
  min-height: 100vh;
  background-color: #f5f5f5;

  .login-wrapper {
    position: relative;
    background: #fff;
    border-radius: 10px;

    .form-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      margin: 0 auto;
      margin-bottom: 10px;
    }

    .eye-icon {
      position: absolute;
      right: 4%;
      bottom: 16%;
      cursor: pointer;
    }
  }
}

.eye-icon {
  position: absolute;
  right: 4%;
  bottom: 16%;
  cursor: pointer;
}

hr {
  background: $primary;
}

img {
  max-width: 100%;
}

.page-title {
  font-size: 1.8rem !important;
  font-weight: bold;
}

.modal-content {
  border-radius: 8px !important;

  .modal-header {
    border-bottom: none;
  }

  .modal-footer {
    border-top: none;
  }
}

.dropdown-menu {
  font-size: 14px !important;

  .dropdown-item {
    padding: 0.25rem 0.75rem;
    font-weight: 500;
    cursor: pointer;
  }

  .dropdown-item:hover {
    background-color: $primary !important;
    color: #fff;
    outline-color: transparent;
  }
}

.file-wrapper {
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  span.file-item {
    background-color: $primary;
    border-radius: 0.25rem;
    font-size: 13px;
    font-weight: bold;
    padding: 0.75rem 0.5rem;

    &.link {
      cursor: pointer;

      &:hover {
        background-color: #d6b748;
      }
    }
  }
}

.notification {
  position: relative;

  span.count {
    background-color: $danger;
    color: #fff;
    padding: 3px 5px;
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
  }

  .dropdown-menu {
    width: 400px;
    max-width: 500px;
    // height: 400px;
    max-height: 500px;
    overflow: auto;
    border-radius: 5px;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;

      h4 {
        font-size: 1.25rem;
        font-weight: bold;
        margin: 0;
      }
    }
  }
}

.notification-item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0.75rem !important;
  border-radius: 5px;
  margin-bottom: 0.75rem;
  opacity: 0.5;

  &.unread {
    opacity: 1;
    background-color: #efefef;
  }

  .avatar-wrapper {
    width: 50px;
    min-width: 50px;
    height: 50px;
    min-height: 50px;
    border-radius: 50%;
    background-color: #e2e2e2;
    margin-right: 1rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content-wrapper {
    width: 100%;

    ._title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      h6 {
        font-size: 14px;
        margin: 0;
        // color: #d8104b;
      }

      span {
        font-size: 12px;
      }
    }

    p._content {
      margin: 0;
      color: #7a7878;
      font-size: 13px;
      white-space: normal;
    }
  }
}

.pro-pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem 0;

  select {
    cursor: pointer;
    border: none;
    height: 40px;
    outline: none;
    padding: 10px;
    border-radius: 0;
    margin-right: 10px;
    font-weight: bold;
    background: #f8f9fa;
    margin-right: 1rem;
  }

  ul.pro-pagination {
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      list-style-type: none;
      cursor: pointer;

      a {
        display: block;
        font-weight: bold;
        padding: 9px 12px;
        background: #f8f9fa;
        transition: 0.3s all;

        &:hover {
          background: #efefef;
        }
      }

      &.active {
        a {
          color: #fff;
          background: $primary;
        }
      }

      &.page-btn {
        a {
          font-weight: 500;

          &:active {
            background-color: $primary;
          }
        }
      }

      &.disabled {
        pointer-events: none;

        a {
          color: #6c757d;
          background-color: #efefef;
        }
      }
    }
  }
}

// .react-datepicker-wrapper {

//   input[type="text"],
//   // input[type="password"],
//   textarea {
//     //display: none;
//     // background-color: #5c67cc !important;
//     border: 1px solid #ced4da;
//     // color: #ffffff;
//     text-align: left;

//     &::placeholder {
//       // color: #ffffff;
//       opacity: 1;
//       /* Firefox */
//     }
//   }
// }

// .react-datepicker__time-box {
//   color: #0C162E;
// }

// .react-datepicker-popper {
//   z-index: 9999;
// }

.image-uploader {}