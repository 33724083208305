.c-pointer {
  cursor: pointer;
}

.p-td-4 {

  .table th,
  .table td {
    padding: 0.25rem !important;
  }
}

.delete-btn {
  &:hover {
    background-color: $danger;
    color: #fff;
  }
}

.text-bold {
  font-weight: bold;
}

.pagination {
  ul {
    padding: 0;
    display: block;
    list-style-type: none;

    li {
      cursor: pointer;
      margin-bottom: 10px;
      display: inline-block;

      .page {
        height: 40px;
        display: block;
        font-weight: bold;
        padding: 9px 12px;
        background: #f8f9fa;
        transition: 0.3s all;

        &:hover {
          background: #efefef;
        }

        &.active {
          color: #000;
          background: $primary;
        }
      }

      select {
        border: 0;
        height: 40px;
        outline: none;
        padding: 10px;
        cursor: pointer;
        border-radius: 0;
        margin-right: 10px;
        font-weight: bold;
        background: #f8f9fa;
      }
    }
  }
}

.arrow-component {
  display: flex;
  align-items: flex-end;

  svg {
    margin-bottom: 10px;
    margin: 10px 5px;
  }
}

.image-wrapper {
  width: 165px;
  height: 220px;
  position: relative;
  border: 1px solid #6c757d;
  overflow: hidden;

  &.wrapper-rounded {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: $primary;

    span {
      width: 100%;
      text-align: center;
      font-size: 10px;
      font-weight: bold;
      color: #000 !important;
    }
  }

  span.img-holder {
    position: absolute;
    color: #6c757d;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    // object-fit: cover;
  }
}

.upload-input {
  // padding: 0.375rem 0.75rem;
  // background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  &.is-rounded {
    flex-direction: column-reverse;
    justify-content: center;
    width: 120px;
    max-width: 100%;
    height: 120px;
    border-radius: 50%;

    span.text {
      font-size: 13px;
      font-weight: bold;
      margin-top: 5px;
    }
  }

  img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    // object-fit: cover;
  }
}

.custom-search-input {
  position: relative;

  span {
    position: absolute;
    top: 10px;
    right: 5px;
    background-color: #fff;
    padding: 0px 5px;
  }
}

// terminal-card
.terminal-btn {
  &.active {
    background-color: $primary !important;
  }
}

.checkbox-wrapper {
  display: inline-block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  transition: ease-in 0.05s;
  -webkit-user-select: none;
  user-select: none;

  &.disabled {
    cursor: default;

    .checkmark {
      background-color: #9cb1ac !important;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 10%;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    background-color: transparent;
    transition: ease-in 0.05s;
    border: 1px solid #000;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 36%;
      bottom: 3px;
      width: 4px;
      height: 9px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(39deg);
    }
  }

  input:checked~.checkmark {
    border: none;
    background-color: $primary;

    &:after {
      display: block;
    }
  }
}

.pro-checkbox-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .custom-checkbox {
    cursor: pointer;
    margin-right: 20px;
    min-width: 195px;
    display: flex;
    align-items: center;

    input {
      margin-right: 0.25rem;
    }
  }
}

.reactPhoneInput {
  display: flex;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &.disabled {
    background: #e9ecef;
  }

  &.PhoneInput--focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }

  .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
      inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor) !important;
  }

  input {
    border: none;
    outline: none;
  }
}

.lds-dual-ring {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  z-index: 999999999;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  height: 106%;

  p {
    font-weight: bold;
    margin-top: 3rem;
    font-size: 2rem;
  }
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 128px;
  height: 128px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #11173b;
  border-color: #11173b transparent #11173b transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}