// Switch.scss

.switch {
    display: flex;
    align-items: center;
    width: 90px;
    /* Adjust the width based on your content */
    height: 30px;
    background-color: #ccc;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &.on {
        background-color: $primary;

        .thumb {
            transform: translateX(60px);
            /* Adjust the position of the thumb */
        }

        .label {
            transform: translateX(-10px);
        }
    }

    &.off {
        background-color: #ccc;

        .thumb {
            transform: translateX(0);
        }

        .label {
            transform: translateX(10px);
        }
    }

    .thumb {
        width: 25px;
        height: 25px;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        top: 9%;
        left: 3px;
        transition: transform 0.3s ease;
    }

    .label {
        flex: 1;
        text-align: center;
        color: #fff;
        /* Adjust the color based on your design */
        font-weight: bold;
        user-select: none;
        font-size: 12px;
        /* Disable text selection */
        transition: transform 0.3s ease;
    }
}